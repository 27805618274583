.infrastructure{
    padding-top: 50px;
    padding-bottom: 50px;
    .subtitle-infraestructure{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: #333333;
    }

    .title-infraestructure{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #0074e0;
    }

    .custom-padding{
        padding-bottom: 50px;
    }

    .custom-col{
        width: 12.33% !important;
    }
}