.clients {
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: #f6f6f6;

  img {
    padding-bottom: 30px;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .vertical-align {
    display: flex;
    align-items: center;
  }
}

