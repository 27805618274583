.plans{
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #f6f6f6;

    .title-plans{
        font-size: 26.5px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #0074e0;
        padding-bottom: 10px;
    }

    .subtitle-plans{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: #333333;
        padding-bottom: 25px;
    }

        .subtitle-blue-plans{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: #0074e0;
        padding-bottom: 15px;
    }

    a{
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #0074e0;
        height: 71px;
    }

    @media only screen and (min-width: 1126px) {
        a{
            height: 65px;
        }
    }

    .nav > li {

        &:hover,
        &:focus,
        &.active {
            a {
                background-color: #ffffff !important;
            }
        }
    }

    .panel {
        border-radius: 0;
        .panel-heading {
            padding: 0;

            a{
                display: block;
                height: auto;
                padding: 10px 15px;
                background-color: #ffffff;
                text-decoration: none;

                &.collapsed{
                    background: #f5f5f5;
                    &:hover, &:focus {
                        background-color: #ffffff;
                    }
                }
            }

        }
    }




    .white-field{
        padding: 30px;
        background: white;
        height: 455px;
        background-image: url('/images/whitefield-background.png'); 
        background-position: bottom;
        background-repeat: no-repeat;
    }

    .custom-icon{
        height: 15px;
        width: 15px; 
        margin-right: 10px;
    }

    .padding-delete{
        padding: 0;
    }

    .white-card{
        background-color: #ffffff;
        margin-bottom: 20px;
    }
}