.portafolio {
  padding-top: 70px;
  padding-bottom: 50px;
  background-color: #f6f6f6;
  h2 {

    font-size: 26.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0074e0;
    padding-bottom: 15px;
  }
  hr {
    border-top: 3px solid rgba(0,0,0,.1);
  }

  .owl-stage {
    padding-left: 0 !important;
  }

  a{

    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0074e0;
  }

  @media only screen and (max-width: 768px){
    .mini-tabs {
      border-bottom: 1px solid @nav-tabs-border-color;
      > li {
        float: left;
        // Make the list-items overlay the bottom border
        margin-bottom: -1px;

        // Actual tabs (as links)
        > a {
          margin-right: 2px;
          line-height: @line-height-base;
          border: 1px solid transparent;
          border-radius: @border-radius-base @border-radius-base 0 0;
          &:hover {
            border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
          }
        }

        // Active state, and its :hover to override normal :hover
        &.active > a {
          &,
          &:hover,
          &:focus {
            color: @nav-tabs-active-link-hover-color;
            background-color: @nav-tabs-active-link-hover-bg;
            border: 1px solid @nav-tabs-active-link-hover-border-color;
            border-bottom-color: transparent;
            cursor: default;
          }
        }
      }
      // pulling this in mainly for less shorthand
      &.nav-justified {
        .nav-justified();
        .nav-tabs-justified();
      }
    }
  }

  .subtitle-portfolio{
    @media only screen and (min-width: 768px){
      height: 14px;
    }
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0074e0;
  }

  .text-portfolio{

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    padding-bottom: 15px;
  }

  .padding-delete{
    padding: 0;
  }

  .custom-icon{
    height: 15px;
    width: 15px; 
    margin-right: 10px;
  }

  .content{
    font-family: @font-family-monospace;
    padding-top: 40px;
  }

  p strong {
    padding-left: 15px;
    display: inline-block;
    position: relative;
    &:after {
      content: '•';
      left: 2px;
      position: absolute;
      top: 0;
    }
  }

  a{
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0074e0;
  }

  .nav > li {
      &:hover,
      &:focus,
      &.active {
          a {
              background-color: #ffffff !important;
          }
      }
  }

  .panel {
      border-radius: 0;
      .panel-heading {
          padding: 0;

          a{
              display: block;
              height: auto;
              padding: 10px 15px;
              background-color: #ffffff;
              text-decoration: none;

              &.collapsed{
                  background: #f5f5f5;
                  &:hover, &:focus {
                      background-color: #ffffff;
                  }
              }
          }

      }
  }
  .white-field{
      padding-top: 30px;
      background: white;
      height: 497px;
      background-image: url('/images/whitefield-background.png'); 
      background-position: bottom;
      background-repeat: no-repeat;
  }
}
