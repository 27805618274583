.home {
  background: url("/images/grupo-1@3x.png");
  background-size: cover;
  background-position: center top;
  min-height: 80vh;
  min-height: 600px;
  padding-bottom: 30px;
  padding-top: 150px !important;

  @media only screen and (min-width: 992px) {
    padding-top: 200px !important;
  }

  .home-title-text {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.27;
    text-align: center;
    color: #ffffff;
    padding-bottom: 10px;
  }

  .home-subtitle-text{

    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .btn{
    font-size: 16px; 
    line-height: 1.88;
    letter-spacing: 0.3px;
    color: #ffffff;
    font-family: @font-family-monospace;
    border-width: 3px;
    border-radius: 4.6px;
    margin-top: 20px;
    transition: background 200ms ease-in-out;
    background-color: rgb(246, 156, 0);

    @media (min-width: @screen-md-min) {
      min-width: 310px;
    }


    &:hover,
    &:focus {
    background-color: lighten(rgb(246, 156, 0), 10%);

    }
  }

}
