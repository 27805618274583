.arrow-controls{
  position: relative;
  top: 35px;;
  text-align: right;
  &:hover{
    color: #2bf4a1;
  }

}

.arrow{
  display: inline-block;
  width: 32px;
  color: #000;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  cursor: pointer;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  transition: color .3s;
}

.arrow_back{
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 30px;
}

.arrow_hide{
  visibility: hidden;
}

.arrow__line{
  width: 100%;
  border-top: 1px solid;
}

.arrow__line-short{
  width: 50%;
  border-top: 1px solid;
  margin-left: auto;
}

.arrow__line_up{
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-bottom: -1px;
}

.arrow__line_down{
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: -1px;
}
