.network{
    padding-top: 80px;
    background-image: url('/images/background-network.png'); 
    padding-bottom: 80px;
    background-position: right;
    background-repeat: no-repeat;
    background-color: #0174e0;

    .img-responsive{
        margin-right: auto;
        margin-left: auto;
    }
}