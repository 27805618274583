.nosotros {
  padding-top: 60px;
  background-color: @white;

  h2 {
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    color: #0074e0;
    padding-bottom: 15px;
  }
  .us-container{
    padding-bottom: 30px;
    padding-top: 30px;
  }

  p{
    padding-bottom: 5px;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #333333;
  }

  .remarked-text{

    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0074e0;
  }

  .custom-image{
      display: block;
      max-width: 300px;
      height: auto;
      margin: 0 auto;
  }

  .icons-container{
    background-color: #f3f3f3;
    padding-top: 65px;
    padding-bottom: 40px;
    padding-left: 85px;
    padding-right: 85px;
  }

  .custom-icon{
    padding:15px;
    border:2px solid @cerulean-blue;
    height: 90px;
    width: 90px;
  }

  .icons-text{
    padding-top: 25px;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #454545;
    margin-bottom: 20px;
  }

  .fix-image{
    min-height: 180px;
  }
}
