.services{
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #f6f6f6;

    .title-services{
        font-size: 26.5px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #0074e0;
        padding-bottom: 10px;
    }

    .subtitle-services{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: #333333;
    }

    .custom-padding{
        padding-bottom: 80px;
    }

    .col-centered{
        float: none;
        margin: 0 auto;
    }
}