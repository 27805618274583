
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import (inline) 'node_modules/owl.carousel/dist/assets/owl.carousel.css';
@import 'node_modules/font-awesome/less/font-awesome';
@fa-font-path: "/fonts/";

@import './bootstrap.less';
@font-family-sans-serif:  "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@import './nosotros.less';
@import './navbar.less';
@import './home.less';
@import './portafolio.less';
@import './historias.less';
@import './arrows.less';
@import './clients.less';
@import './contact.less';
@import './timeline.less';
@import './plans.less';
@import './metodologies.less';
@import './services.less';
@import './infrastructure.less';
@import './network.less';
@import './integration.less';
@import './values.less';