/* Timeline */

.trayectoria{
  color: @white;
  background-color: @cerulean-blue;


  h2 {
    font-family: @font-family-sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: @white;
  }

  h4 {
    font-family: @font-family-sans-serif;
    font-size: 18px;
    color: @white;
  }

}

.timeline {
  list-style: none;
  position: relative;
  margin-bottom: 0;
  

  &:before {
    top: 0px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 10px;
    background-color: #eeeeee;
    margin-left: -1.5px;
  }

  .timeline-item {
    //margin-bottom: 20px;
    position: relative;

    &:before,
    &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }

    .timeline-badge {
      color: @cerulean-blue;
      width: 54px;
      height: 50px;
      line-height: 52px;
      font-size: 22px;
      text-align: center;
      position: absolute;
      top: 18px;
      margin-left: -22px;
      background-color:@cerulean-blue;
      border: 8px solid #ededee;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;

      i, .fa, .glyphicon {
        top: 2px;
        left: 0px;
      }
      .primary {
        background-color: #1f9eba;
      }
      .info {
        background-color: #5bc0de;
      }
      .success {
        background-color: #59ba1f;
      }
      .warning {
        background-color: #d1bd10;
      }
      .danger {
        background-color: #ba1f1f;
      }
    }

    .timeline-panel {
      position: relative;
      width: 98%;
      float: left;
      right: 16px;
      border: 1px solid transparent;
      background: transparent;
      border-radius: 2px;
      padding: 20px;

      &:before {
        position: absolute;
        top: 26px;
        right: -16px;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 16px solid transparent;
        border-right: 0 solid transparent;
        border-bottom: 16px solid transparent;
        content: " ";
      }

      .timeline-title {
        margin-top: 0;
      }

      .fix-text{
        font-size: 22px;
      }

      .timeline-body > p,
      .timeline-body > ul {
        margin-bottom: 0;
        color: @white;
        font-familY: @font-family-monospace;
        font-size: 14px;
      }
      .timeline-body > p + p {
        margin-top: 5px;
        color: @white;
        font-familY: @font-family-monospace;
        font-size: 14px;
      }
    }
    & .timeline-panel {
      float: right;
      left: 15px;
    }

    & .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto;
    }
  }
}
