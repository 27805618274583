
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto+Mono');
@import 'node_modules/bootstrap/less/bootstrap.less';
@import 'block-grid';
@import '_variables';

@font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-monospace: "Roboto Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
@navbar-height: 78px;
@navbar-default-bg: transparent;
@icon-font-path: "/fonts/";

@white: #ffffff;
@black: #161616;
@cerulean-blue: #0074e0;
@black-40: rgba(0, 0, 0, 0.4);
@aqua-marine: #2cf0a1;
@wintergreen: #2bf4a1;
@greyish-brown: #414141;

@grid-float-breakpoint: @screen-md-min;
@link-color:@wintergreen;


body {
  position: relative;
}

#footer{
  background-color: #1b1b1b;

  .span{
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f6f6f6;
    padding-top: 25px;
    padding-right: 80%;
  }

  a{
    padding-left: 10px;
  }


  p {

    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f6f6f6;
    padding-top: 25px;
  }
}


