.values{
    background-color: #0074e0;
    padding-bottom: 50px;
    padding-top: 30px;
    text-align: center;
    background-image: url('/images/values-background.png');
    background-repeat: no-repeat;
    background-position: center;

    h2 {
    font-family: @font-family-sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    padding-bottom: 50px;
    }

    .img-responsive{
        margin-left: auto;
        margin-right: auto;
    }
}