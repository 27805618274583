.historias {
  color: @cerulean-blue;
  background-color: @white;
  padding-top: 70px;
  padding-bottom: 50px;

  h2 {
    font-size: 26.5px;
    font-weight: bold;
    text-align: center;
  }

  .arrows{
    position: absolute;
    right: 0;
    z-index: 99;
    @media (max-width: 768px) { 
      position: relative;
      right: auto;
      text-align: center;
    }
  }

  .brand{
    @media (max-width: 768px) { 
      text-align: center;
      margin-top: 16px;
    }
  }

  .arrow{
    color: @cerulean-blue;
  }

  .arrow-controls{
    top: 0;
  }

  .sector-title-text{
    padding-right: 5px;
    padding-left: 15px;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color:@cerulean-blue;
  }

  .sector-text{

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }

  .arrow_back{
    margin-right: 0px !important;
  }

  .border{
    border: 1px solid @cerulean-blue;
    display: inline-block;
    padding: 12px 8px 8px;
  }

  hr{
    border-top: 1px solid @cerulean-blue;
  }

  .content {

    font-size: 14px;
  }

  .logo{
    width: 241px;
    height: 58px;
    object-fit: contain;

  }

  ul{
    color: #333333;
    padding-left: 15px;
  }

  p {
    color: @cerulean-blue;
  }
}
