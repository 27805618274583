.integration{
    padding-top: 80px;
    background-image: url('/images/integration-background.png'); 
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 80px;

    .img-responsive{
        margin-left: auto;
        margin-right: auto;
    }
}