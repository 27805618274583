.metodologies{
    padding-top: 50px;
    padding-bottom: 50px;

    .title-metodologies{
        font-size: 26.5px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #0074e0;
        padding-bottom: 10px;
    }

    .subtitle-metodologies{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        padding-bottom: 25px;
    }

    li{
        border: none;
    }

    span{
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #0074e0;
    }

    .grey-text{
        color: #333333;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
    }

    .blue-plus{
        font-size: 48px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: #0074e0;
        padding-top: 18px;
        padding-right: 1px;
    }

    .custom-screen{
        padding-bottom: 10px;
    }

    .custom-icon{
        padding-top: 25px;
    }

    .blue-text{
        color: #0074e0;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        margin-bottom: 30px;
    }

    .padding-icon{
            margin-right: 5px;
    }

    .responsive-padding{
        padding-bottom: 20px;
    }

    @media only screen and (max-width: 992px) {
        .mini-center{
            text-align: center;
            padding-top: 10px;
        }

    }
}