.navbar-brand > img {
  position: relative;
  top: -12px;
}

.navbar-default {
  background-color: #0074e0;
  border-bottom: 1px solid #00ff9d;
}

.navbar-default .navbar-nav {


  > li{
    &.active,
    &:hover,
    &:focus {
      &:before {
        width: 100%;
      }
      > a, > a:hover, > a:focus {
        color: #2bf4a1;
      }
    }

    &:before{
      width: 0%;
      display: inline-block;
      border-bottom: 3px solid #2bf4a1;
      position: absolute;
      bottom: 20px;
      @media (max-width: @screen-sm-max){
        bottom: 0px;
      }
      left: 0;
      -webkit-transition: width .3s ease-in-out;
      -o-transition: width .3s ease-in-out;
      transition: width .3s ease-in-out;
      content: ' ';
      position: absolute;
    }

    > a {
      position: relative;
      -webkit-transition: color 3s;
      -o-transition: color .3s;
      transition: color .3s;
      color: #fff;
      text-decoration: none;
      font-family: @font-family-monospace;
    }
  }
}

  @media screen and (max-width: 1199px) and (min-width: 990px) {
    .custom-logo{
        width: 96px !important;
        padding-top: 10px !important;
      }
  }
