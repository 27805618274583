.contact{
  background-color: @cerulean-blue;
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url('/images/background-info.png');

  form {
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  h2{

    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding-bottom: 15px;
  }

  .contact-text{

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }

  .number-text{

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  span{
    padding-left: 5px;
  }

  input {
    &:focus {
      background-color: #ffffff;
      box-shadow: 4.5px 7.8px 18px 0 rgba(173, 178, 199, 0.62);
    }
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color:  #eff0f4;
  }

  textarea {
    &:focus {
      background-color: @white;
      box-shadow: 4.5px 7.8px 18px 0 rgba(173, 178, 199, 0.62);
    }
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color:  #eff0f4;
  }

  label {
    font-family: @font-family-sans-serif;
    font-size: 12px;
    line-height: 2.33;
    letter-spacing: 1.2px;
    text-align: left;
    color: #a8acb9;
  }

  button {
    font-family: @font-family-sans-serif;
    text-align: center;
    height: 50px;
    border-radius: 5px;
    background-color: @cerulean-blue;
  }

  p {
    font-family: @font-family-monospace;
    font-size: 16px;
    text-align: left;
  }

  .custom-padding{
    padding-top: 20px;
  }

  a {
    font-family: @font-family-monospace;
    font-size: 16px;
    text-align: left;
  }

  #map{
    height: 500px;
  }

  .btn{
    font-size: 16px; 
    line-height: 1.88;
    letter-spacing: 0.3px;
    color: #ffffff;
    font-family: @font-family-monospace;
    border-width: 3px;
    border-radius: 4.6px;
    background-color: #f69c00;
    transition: background 200ms ease-in-out;

    &:hover,
    &:focus {
      background-color: lighten(rgb(246, 156, 0), 10%);
      border-color: none;
    }
  }

}
